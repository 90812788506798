exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-face-reading-checkout-tsx": () => import("./../../../src/pages/face-reading/checkout.tsx" /* webpackChunkName: "component---src-pages-face-reading-checkout-tsx" */),
  "component---src-pages-face-reading-index-tsx": () => import("./../../../src/pages/face-reading/index.tsx" /* webpackChunkName: "component---src-pages-face-reading-index-tsx" */),
  "component---src-pages-face-reading-paywall-tsx": () => import("./../../../src/pages/face-reading/paywall.tsx" /* webpackChunkName: "component---src-pages-face-reading-paywall-tsx" */),
  "component---src-pages-face-reading-quiz-tsx": () => import("./../../../src/pages/face-reading/quiz.tsx" /* webpackChunkName: "component---src-pages-face-reading-quiz-tsx" */),
  "component---src-pages-face-reading-success-checkout-onboarding-product-tsx": () => import("./../../../src/pages/face-reading/success-checkout/onboarding-product.tsx" /* webpackChunkName: "component---src-pages-face-reading-success-checkout-onboarding-product-tsx" */),
  "component---src-pages-face-reading-success-checkout-onboarding-reports-1-tsx": () => import("./../../../src/pages/face-reading/success-checkout/onboarding-reports-1.tsx" /* webpackChunkName: "component---src-pages-face-reading-success-checkout-onboarding-reports-1-tsx" */),
  "component---src-pages-face-reading-success-checkout-onboarding-reports-2-tsx": () => import("./../../../src/pages/face-reading/success-checkout/onboarding-reports-2.tsx" /* webpackChunkName: "component---src-pages-face-reading-success-checkout-onboarding-reports-2-tsx" */),
  "component---src-pages-face-reading-success-checkout-onboarding-reports-location-tsx": () => import("./../../../src/pages/face-reading/success-checkout/onboarding-reports-location.tsx" /* webpackChunkName: "component---src-pages-face-reading-success-checkout-onboarding-reports-location-tsx" */),
  "component---src-pages-face-reading-success-checkout-onboarding-reports-time-tsx": () => import("./../../../src/pages/face-reading/success-checkout/onboarding-reports-time.tsx" /* webpackChunkName: "component---src-pages-face-reading-success-checkout-onboarding-reports-time-tsx" */),
  "component---src-pages-face-reading-success-checkout-onboarding-skip-trial-1-tsx": () => import("./../../../src/pages/face-reading/success-checkout/onboarding-skip-trial-1.tsx" /* webpackChunkName: "component---src-pages-face-reading-success-checkout-onboarding-skip-trial-1-tsx" */),
  "component---src-pages-face-reading-success-checkout-onboarding-skip-trial-2-tsx": () => import("./../../../src/pages/face-reading/success-checkout/onboarding-skip-trial-2.tsx" /* webpackChunkName: "component---src-pages-face-reading-success-checkout-onboarding-skip-trial-2-tsx" */),
  "component---src-pages-face-reading-success-checkout-onboarding-welcome-tsx": () => import("./../../../src/pages/face-reading/success-checkout/onboarding-welcome.tsx" /* webpackChunkName: "component---src-pages-face-reading-success-checkout-onboarding-welcome-tsx" */),
  "component---src-pages-face-reading-summary-tsx": () => import("./../../../src/pages/face-reading/summary.tsx" /* webpackChunkName: "component---src-pages-face-reading-summary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

